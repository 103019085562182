import React from "react";
import UserService from "../services/user.service";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Box } from '@mui/system';
import { useState, useEffect } from 'react';
import { Button } from '@mui/base/Button';
import { useNavigate } from "react-router-dom";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CircularProgress from '@mui/material/CircularProgress';

import SharePointService from '../services/sharepoint.service';

import LenaIMG from './../assets/lena_mueller.jpg';
import MonikaIMG from './../assets/monika_rank.jpg';

export default function CustomerManager() {
    const currentUser = JSON.parse(localStorage.getItem("user-data"))

    const [customerManager, setCustomerManager] = useState("");
    const getCustomerManager = (e) => { setCustomerManager(e) }

    const SPgetCustomerManager = async () => {
        let result = await SharePointService.getCustomerManager(currentUser.email)
        getCustomerManager(result)
    }

    const navigate = useNavigate();
    const navigateToWarranty = (e) =>{
        e.preventDefault();
        navigate('/warranty');
        // window.location.reload();
      }

    useEffect(() => {
        try {
            SPgetCustomerManager()
        } catch (e) {
            console.error(e);
        }

        
    }, []);

    var customerManagerHTML = 
        <Grid container>
            <Grid item xs={12}>
                <Paper elevation={1} >
                <Box pt={3} px={3} textAlign={"left"}>
                <h2>Ich bin für Sie da</h2><br></br>
                    <CircularProgress />
                </Box>
                <Box py={3} px={3} textAlign={'center'}>
                </Box>                
            </Paper>
            </Grid>
            
        </Grid>
    ;

    if (customerManager == 'Monika Rank') {
        customerManagerHTML = 
        <Grid container>
            <Grid item xs={12}><Paper elevation={1} >
                <Box pt={3} px={3}>
                    <h2>Ich bin für Sie da</h2>
                </Box>
                <Box sx={{display: 'flex'}}>
                    <Box pt={3} px={3}>
                        <img src={MonikaIMG} height="150"/>
                    </Box>
                    <Box py={3} px={3}>
                        <h5>Kundenmanagerin<br></br> Monika Rank<br></br></h5>
                        <span>Tel: 07941 6460 513</span><br></br>
                        <a href="mailto:monika.rank@weinberger-immobilien.de">E-Mail schreiben</a>
                    </Box>
                </Box>
                <Box py={3} px={3} textAlign={"center"}>
                    <Button onClick={navigateToWarranty} className='btn prim'>Reklamation</Button>
                </Box> 
            </Paper>      
            </Grid>
        </Grid>
    }

    if (customerManager == 'Lena Müller') {
        customerManagerHTML = 
        <Grid container py={3} px={3}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h2>Ich bin für Sie da</h2>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <img src={LenaIMG} height="150"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <h5>Kundenmanagerin<br></br> Lena Müller<br></br></h5>
                        <span>Tel: 07941 6460 514</span><br></br>
                        <a href="mailto:lena.mueller@weinberger-immobilien.de">E-Mail schreiben</a>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={navigateToWarranty} className='btn prim'>Reklamation</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    }

    return( customerManagerHTML
        
    )
}