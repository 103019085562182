import React from 'react';
import { useField, useFormikContext } from 'formik';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import {Box, Typography} from '@mui/material';


const Dringlichkeit = () => {
  const [dringlichkeitField, , dringlichkeithelpers] = useField('dringlichkeit');
  const [dringlichkeitDAYSField, , dringlichkeitDAYShelpers] = useField('dringlichkeitDAYS');
  const { values } = useFormikContext();
  
  const handleOptionChange = (event) => {
    dringlichkeithelpers.setValue(event.target.value);

    switch (event.target.value){
      case 'Gefahr in Verzug':
        dringlichkeitDAYShelpers.setValue(0);
        break;

      case 'Eingeschränkte Funktion':
        dringlichkeitDAYShelpers.setValue(7);
        break;

      case 'Schönheitsreparatur':
        dringlichkeitDAYShelpers.setValue(14);
        break;
    }
    
  };

  const RadioBox = ({ vorlaufzeit, label, sublabel, value, checked, onChange, ...props }) => {
    let isChecked = false;
    if (dringlichkeitField.value === value){isChecked = true;} 
    const boxClassName = isChecked ? 'checked-box' : 'unchecked-box';
    return (
      <FormControlLabel
        className={boxClassName}
        control={<Radio {...props} required checked={isChecked} onChange={onChange} value={value} />}
        label={
          <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            <Box >
              <Box sx={{width: '100%', display: 'flex;', justifyContent: 'space-between', borderBottom: '1px solid #ced5de', mb: 3}}>
                <Typography sx={{color: '#8C939C'}} mb={3}>Vorlaufzeit</Typography>
                <Typography sx={{color: '#3a8787', fontWeight: 'bold'}} mb={3}>{vorlaufzeit}</Typography>
              </Box>
              
              <Typography variant="h6" mb={3}>{label}</Typography>
              <Typography dangerouslySetInnerHTML={sublabel}></Typography>
            </Box>
            {isChecked == true ? 
              <CheckRoundedIcon sx={{ fontSize: 60, color: 'white', padding: '10px', backgroundColor: '#3A8787', borderRadius: '100%', border: '1px solid #3A8787'  }}/>: 
              <CheckRoundedIcon sx={{ fontSize: 60, color: '#F0F2F5', border: '1px solid #F0F2F5',padding: '10px', backgroundColor: 'white', borderRadius: '100%'}}/>
            }
          </Box>
        }
      />
    );
  };

  const sublabel1 = { __html: "<p>z.B. Wasserrohrbruch, Heizungsausfall usw. Handlungsbedarf <strong>sofort!</strong><br><br> Bitte wählen Sie die Notfallnummer des Handwerkers/Verwalters.</p>" }
  const sublabel2 = { __html: "<p>z.B. Rollladen klemmt, Videosprechanlage oder Steckdose geht nicht, Türe streift usw.<br><br> Handlungsbedarf binnen <strong>2 Wochen</strong> ab Eingang bei WEINBERGER!</p>" }
  const sublabel3 = { __html: "<p>z.B. Spannungsriss in Fliese oder Risse in Tapete usw.<br><br> Handlungsbedarf binnen <strong>1 Monat</strong> ab Eingang bei WEINBERGER!</p>"}

  return (
    <div>
      <h2>Dringlichkeit</h2>
      <FormControl>
        <RadioGroup name="dringlichkeit">
          <Grid container spacing={2} mb={6} mt={1}>
            <Grid item xs={12} md={4} >
              <RadioBox 
                vorlaufzeit= "Keine"
                label="Gefahr in Verzug" 
                sublabel= {sublabel1}
                value="Gefahr in Verzug" 
                checked={values.dringlichkeit === 'Gefahr in Verzug'} 
                onChange={handleOptionChange} 
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <RadioBox 
                vorlaufzeit= "min. 7 Tage"
                label="Eingeschränkte Funktion" 
                sublabel= {sublabel2}
                value="Eingeschränkte Funktion" 
                checked={values.dringlichkeit === 'Eingeschränkte Funktion'} 
                onChange={handleOptionChange} 
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <RadioBox 
                vorlaufzeit= "min. 14 Tage"
                label="Schönheitsreparatur" 
                sublabel={ sublabel3 }
                value="Schönheitsreparatur" 
                checked={values.dringlichkeit === 'Schönheitsreparatur'} 
                onChange={handleOptionChange} 
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
  </div>
  );
};

export default Dringlichkeit;