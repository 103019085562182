import * as React from 'react';
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import SharePointService from '../services/sharepoint.service';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function CommonDocuments() {
    var categories = [];
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {setValue(newValue)};

    const [files, setFiles] = useState([]);
    const handleFiles = (e) => { setFiles(e);};

    const [working, setWorking] = useState(false);
    const handleWorking = (e) => { setWorking(e)};

    const  GetSPFiles = async () => {
        handleWorking(true)
        handleFiles(await SharePointService.getCommonDocuments())
        handleWorking(false)
    }

    const SPfetchFile = async (file) => {
        try {
            let SPfile = await SharePointService.getDownloadFile(file)
            const blob = new Blob([SPfile], { type: 'application/pdf' });
      
            // Create a download link
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', file.name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const SPopenFile = async (file) => {
        try {
            let SPfile = await SharePointService.getDownloadFile(file)
            const blob = new Blob([SPfile], { type: 'application/pdf' });
      
            // Create a download link
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    useEffect(() => {
        try {
            GetSPFiles()
            
        } catch (e) {
            console.error(e);
        }
    }, []);
    

    var content = <Typography variant='h6' mt={4}>Keine Dateien zur Anzeige gefunden, bitte melden Sie sich bei Ihrer Kundenmanagerin.</Typography>
    if (files.message != 'Wrong Secret') {
        content = <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile onChange={handleChange} aria-label="basic tabs example">
                    {files.map((folder, categoryIndex) => (
                        <Tab key={categoryIndex} label={folder.category}></Tab>
                    ))}
                </Tabs>
            </Box>
            {files.map((folder, index) => (
                
                <CustomTabPanel value={value} index={index}>
                    <Box sx={{ overflowX: "auto" }}>
                        <Table sx={{ minWidth: 150 }} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" sx={{ '&:hover': {textDecoration: "none"}, fontWeight: "bold", '@media screen and (min-width: 960px)': { width: "50%"} }} >Dateiname</TableCell>
                                    <TableCell align="right" sx={{ '&:hover': {textDecoration: "none"}, fontWeight: "bold" }} >Letzte Änderung</TableCell>
                                    <TableCell align="right" sx={{ '&:hover': {textDecoration: "none"}, fontWeight: "bold", width: "100px" }} >Download</TableCell>
                                </TableRow>
                            </TableHead> 

                            {folder.files.files.map((file, index) => (
                                <TableRow>
                                <TableCell sx={{'&:hover': {cursor: "pointer"}, '@media screen and (min-width: 960px)': { width: "50%"} }} onClick={() => SPopenFile(file)}>{file.name}</TableCell>
                                {/* <Hidden mdDown>
                                    <TableCell >{file[1].category.substring(3)}</TableCell>
                                </Hidden> */}
                                <TableCell align="right" sx={{'&:hover': {textDecoration: "none"}}}>{file.edited}</TableCell>
                                <TableCell align="right" sx={{ width: "100px", '&:hover': {cursor: "pointer"} }} onClick={() => SPfetchFile(file)}><DownloadIcon /></TableCell>
                            </TableRow> 
                            ))}
                        </Table>
                    </Box>
                </CustomTabPanel>   
            ))}      
        </>
    }

    return (
        <Box sx={{ width: '100%' }}>
            { working ? 
                <Box py={10}>
                    <CircularProgress />
                </Box>
            :
                content
            }
        </Box>

    );
}