import axios from "axios";

const API_URL = process.env.REACT_APP_USER_API_URL;

const getCurrentUser = () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': JSON.parse(localStorage.getItem("user")).accessToken
    }
  }

  // Return the axios promise
  return axios
    .post(API_URL + "getcurrentUser", {}, config)
    .then((response) => {
      localStorage.setItem("user-data", JSON.stringify(response.data));
      return response.data; // Return the user data
    });
};

const updateUser = async (fullname="", phone="", email="", mobile="", adress="", zip="", ort="") => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': JSON.parse(localStorage.getItem("user")).accessToken
    }
  }

  const data = {
    "userId": JSON.parse(localStorage.getItem("user")).id,
    "fullname": fullname,
    "phone": phone,
    "email": email,
    "mobile": mobile,
    "adresse": adress,
    "zip": zip,
    "ort": ort
  }

  return await axios.put(API_URL + "updateUser", data, config)
}

const ADMIN_updateUser = async (userId="", fullname="", phone="", email="", mobile="", adress="", zip="", ort="", secret="") => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': JSON.parse(localStorage.getItem("user")).accessToken
    }
  }

  const data = {
    "userId": userId,
    "fullname": fullname,
    "phone": phone,
    "email": email,
    "mobile": mobile,
    "adresse": adress,
    "zip": zip,
    "ort": ort,
    "secret": secret
  }
  
  return await axios.put(API_URL + "admin_updateUser", data, config)
}

const getAllUsers = async () =>{
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': JSON.parse(localStorage.getItem("user")).accessToken
    }
  }

  let result = await axios.post(API_URL + "getAllUsers", {}, config)
  return result.data

}

const UserService = {
  getCurrentUser,
  updateUser,
  ADMIN_updateUser,
  getAllUsers
}

export default UserService;